import {Component} from '@angular/core';
import {Session} from '../../services/Session';
import {SendPostForm} from "../../services/SendPostForm";
declare var $:any;

@Component({
  selector: 'capital-equipment',
  templateUrl: './capital-equipment.html',
})

export class CapitalEquipmentCmp {
  public formData:any = {'idstamp': 'AL2asT2SgcA9LUHSyoyxHwEfU74hMZvaedGmtK/SqGg='};
  public messageSent = false;

  submit() {
    let sender = new SendPostForm();
    sender.send('https://purchaseclinic.wufoo.com/forms/sdnmgk30xdci32/#public', this.formData);
    this.messageSent = true;
    $('html,body').animate({scrollTop: 0}, 100);
  }
}
